import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { BarraDeProgresso, Select, Dialog, Drawer } from "../components";
import MaskedInput from "react-text-mask";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useQuestionario } from "../hooks";
import { useTheme, Container } from "@mui/material";
import {
  CircularProgress,
  Hidden,
  Divider,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import PostAddIcon from "@mui/icons-material/PostAdd";

import { motion, AnimatePresence } from "framer-motion";
import { leftToPosition } from "../effects";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
//

import { API_KEY } from "../config";

const opcoesSexo = [
  ["", ""],
  ["F", "Feminino"],
  ["M", "Masculino"],
];
const opcoesFilho = [
  ["", ""],
  ["N", "Não"],
  ["S", "Sim"],
];

// Validacao para as informacoes profissionais
const schemaInformacoesProfissionais = yup.object().shape({
  empresa: yup.string(),
  cargo: yup.string(),
  dataAdmissao: yup.date().required(),
  dataDesligamento: yup.date().required(),
  principaisAtividades: yup.string(),
});
// Tipo dos campos
const tipoCampo = "filled";

// Componente que formata e retornar um campo
const RenderCampo = ({ inputProps, control, ele }) => {
  // Gerar o inputProps
  let InputProps = ele.inputProps;
  if (inputProps) InputProps = { ...InputProps, ...inputProps };

  return (
    <Grid item {...ele.gridSystem}>
      <Controller
        control={control}
        name={ele.name}
        rules={ele.rules}
        render={({ field }) => {
          const compo = (
            <TextField
              {...field}
              variant={ele.variant}
              fullWidth
              disabled={ele.disabled}
              label={ele.label}
              inputProps={InputProps}
              InputLabelProps={ele.inputLabelProps}
              minRows={ele?.minRows || 1}
              multiline={ele?.multiline || false}
              helperText={ele.helpText}
              error={ele.error}
              type={ele.type}
            />
          );
          // Se ele for um campo select o resultado o retorno e diferente
          if (ele.type === "select")
            return (
              <Select
                {...field}
                options={ele.options}
                fullWidth
                variant={ele.variant}
                label={ele.label}
                error={ele.error}
                helperText={ele.helpText}
                disabled={ele.disabled}
              />
            );

          return ele.mask ? (
            <MaskedInput
              {...field}
              mask={ele.mask}
              onBlur={ele.onBlur}
              onKeyUp={ele.onKeyUp}
              render={(ref, props) => (
                <TextField
                  variant={ele.variant}
                  disabled={ele.disabled}
                  inputRef={ref}
                  {...props}
                  InputLabelProps={ele.inputLabelProps}
                  fullWidth
                  label={ele.label}
                  inputProps={ele.inputProps}
                  helperText={ele.helpText}
                  error={ele.error}
                  type={ele.type}
                />
              )}
            />
          ) : (
            compo
          );
        }}
      />
    </Grid>
  );
};

const Cadastro = () => {
  // Para referenciar o captcha invisivel
  const recaptchaRef = useRef();
  // Estado que controla a atividade do token
  const [token, setToken] = useState(null);

  const [dados, setDados] = useState(null);
  const [exibirTermo, setExibirTermo] = useState(null);

  // Verifica se tem dados salvos no armazenamento local e inicia a partir dele
  const ultimosDadosSalvos = localStorage?.getItem("dadosForm")
    ? JSON.parse(localStorage?.getItem("dadosForm"))
    : null;
  const contadorAtual = localStorage?.getItem("contadorAtual")
    ? parseInt(localStorage?.getItem("contadorAtual"))
    : null;
  // Veja se é mobile
  const isMobile = useTheme().breakpoints?.values?.sm > window.innerWidth;

  // Usando o useEffect para recuperar os dados necessarios
  useEffect(() => {
    (async () => {
      const resp = await axios.get("/obter_dados");
      setDados(resp.data);
    })();
  }, []);

  const { resposta, avancar, recuar, contador } = useQuestionario(
    ultimosDadosSalvos,
    contadorAtual
  );
  // Valida o contador para saber se temos que avancar ou recuar no questionario
  let componente;
  switch (contador) {
    case 1:
      componente = (
        <InformacoesProfissionais
          key={1}
          resposta={resposta[1]}
          avancar={avancar}
          recuar={recuar}
          variants={leftToPosition}
          initial="initial"
          animate="animate"
          exit="exit"
        />
      );
      break;
    case 2:
      componente = (
        <DisponibilidadeDeLoja
          key={2}
          resposta={resposta[2]}
          avancar={avancar}
          recuar={recuar}
          regioes={dados?.regioes}
          variants={leftToPosition}
          initial="initial"
          animate="animate"
          exit="exit"
        />
      );
      break;
    case 3:
      componente = (
        <CargoDeInteresse
          key={3}
          resposta={resposta[3]}
          avancar={avancar}
          recuar={recuar}
          cargo={dados?.cargo}
          variants={leftToPosition}
          initial="initial"
          animate="animate"
          exit="exit"
        />
      );
      break;
    case 4:
      componente = (
        <VerRespostasPreenchidas
          key={4}
          setExibirTermo={setExibirTermo}
          recaptchaRef={recaptchaRef}
          cargo={dados?.cargo}
          estadoCivil={dados?.estadoCivil}
          escolaridade={dados?.escolaridade}
          respostas={resposta}
          recuar={recuar}
          regioes={dados?.regioes}
          variants={leftToPosition}
          initial="initial"
          animate="animate"
          exit="exit"
        />
      );
      break;
    default:
      componente = (
        <InformacoesPessoais
          key={0}
          token={token}
          setToken={setToken}
          recaptchaRef={recaptchaRef}
          estadoCivil={dados?.estadoCivil}
          escolaridade={dados?.escolaridade}
          resposta={resposta[0]}
          avancar={avancar}
          variants={leftToPosition}
          initial="initial"
          animate="animate"
          exit="exit"
        />
      );
      break;
  }
  // Calcula o progresso da barra
  const progresso = ((contador + 1) / 5) * 100 - 1;
  // Veja se tudo foi carregado
  if (!dados) return <Container></Container>;
  // Verifica se ha necessidade de exibir o corpo
  let corpoDrawer;
  const fnFecharDrawer = () => setExibirTermo(false);

  if (exibirTermo) {
    corpoDrawer = <ExibirTermoAceite termo={dados?.termo_aceite} />;
  }

  return (
    <Container maxWidth="lg">
      {isMobile ? (
        <Drawer corpo={corpoDrawer} fecharDrawer={fnFecharDrawer} />
      ) : (
        <Dialog corpo={corpoDrawer} fecharDialogo={fnFecharDrawer} />
      )}
      <ToastContainer position="bottom-center" />
      <BarraDeProgresso value={progresso > 100 ? 100 : progresso} />
      <AnimatePresence>{componente}</AnimatePresence>
      <br /> <br /> <br /> <br /> <br /> <br />
      {/* <div style={{opacity: 0}}> */}
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={API_KEY} />
      {/* </div> */}
    </Container>
  );
};
// Componnete que exibe o termo aceite em Markdown
const ExibirTermoAceite = ({ termo }) => {
  return (
    <Container sx={{ height: "85vh" }} maxWidth="md">
      <ReactMarkdown children={termo} remarkPlugins={[remarkGfm]} />
    </Container>
  );
};
// Validacao de informacoes pessoais
const schemaInformacoesPessoais = yup.object().shape({
  nome: yup.string().required(),
  email: yup.string().email().required(),
  celular: yup
    .string()
    .matches(/^\([0-9]{2}\) [0-9]{5}-[0-9]{4}$/g)
    .required(),
  //nascimento: yup.string().required(),
  nascimento: yup
    .string()
    .matches(
      /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/g
    )
    .required(),
  sexo: yup
    .string()
    .matches(/^(F|M){1}$/g)
    .required(),
  filho: yup
    .string()
    .matches(/^(S|N){1}$/g)
    .required(),
  qtd_filho: yup.number().integer().min(0).required(),
  estadoCivil: yup
    .string()
    .matches(/^(S|C|D|V){1}$/g)
    .required(),
  escolaridade: yup
    .string()
    .matches(/^(FI|F|M|SI|SC){1}$/g)
    .required(),
  cep: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/g)
    .required(),
});
// Componente que exibe o formulario de informacoes pessoais
const InformacoesPessoais = (props) => {
  // Estado que força o usuario a preencher o CPF (torna todos os campos bloqueados por padrao)
  // Se ja tiver sido respondido deixa os campos bloqueados livres
  const [campoBloqueado, setCampoBloqueado] = useState(
    props?.resposta ? false : true
  );

  // Criando um estado que não permita o botao avancar de ser clicado
  const [aguardar, setAguardar] = useState(false);

  // Extraindo os recursos para lidar com este formulario
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaInformacoesPessoais),
    defaultValues: props.resposta,
  });
  // Consulta para buscar o cpf pelo campo digitado
  const fnBuscaCPF = async (e) => {
    const valor = e.target.value
      .replace(/_/g, "")
      .replace(/\./g, "")
      .replace("-", "")
      .trim();

    if (valor.search(/^[0-9]{11}$/g) === -1) return false;
    // Bloquear os campos pois o processo ja foi acionado
    setCampoBloqueado(true);

    // Caso o token nao esteja mais ativo reative-o
    let token;
    if (!props.token) {
      token = await props.recaptchaRef.current.executeAsync();
      props.setToken(token);
    } else {
      await props.recaptchaRef.current.reset();
      token = await props.recaptchaRef.current.executeAsync();
    }

    // Envia o token do captcha e o cpf para verificar se tem cadastro
    const formData = new FormData();
    formData.append(
      "dados",
      JSON.stringify({
        cpf: valor,
        captcha: token,
      })
    );
    try {
      const resp = await axios.post("/recuperar_dados_candidato", formData);

      if (resp.status !== 200) {
        toast.dark("ERRO INTERNO DO SERVIDOR", {
          type: "error",
        });
        setCampoBloqueado(true);
        return false;
      }

      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        setCampoBloqueado(true);
        return false;
      }
      // Liberando todos os campos Pois ou tem cadastro ou e novo
      setCampoBloqueado(false);
      // Veja se foram retornados dados. Se sim preencha os campos
      if (resp.data?.length > 0) {
        let abaPessoais = resp.data[0];
        // Preenchimento de todos os campos do formulario
        setValue("nome", abaPessoais["nome"]);
        setValue("email", abaPessoais["email"]);
        setValue("celular", abaPessoais["celular"]);
        setValue("nascimento", abaPessoais["nascimento"]);
        setValue("sexo", abaPessoais["sexo"]); //
        setValue("filho", abaPessoais["filho"]); //
        setValue("qtd_filho", abaPessoais["qtd_filho"]);
        setValue("estadoCivil", abaPessoais["estadoCivil"]); //
        setValue("escolaridade", abaPessoais["escolaridade"]); //
        setValue("cep", abaPessoais["cep"]);
        setValue("endereco", abaPessoais["endereco"]);
        setValue("numero", abaPessoais["numero"]);
        setValue("complemento", abaPessoais["complemento"]);
        setValue("bairro", abaPessoais["bairro"]);
        setValue("cidade", abaPessoais["cidade"]);
        setValue("estado", abaPessoais["estado"]);
      } else {
        // Limpeza dos campos
        setValue("nome", "");
        setValue("email", "");
        setValue("celular", "");
        setValue("nascimento", "");
        setValue("sexo", ""); //
        setValue("filho", ""); //
        setValue("qtd_filho", "");
        setValue("estadoCivil", ""); //
        setValue("escolaridade", ""); //
        setValue("cep", "");
        setValue("endereco", "");
        setValue("numero", "");
        setValue("complemento", "");
        setValue("bairro", "");
        setValue("cidade", "");
        setValue("estado", "");
      }
    } catch (error) {
      toast.dark("ERRO AO TENTAR RECUPERAR DADOS", {
        type: "error",
      });
      // Liberando todos os campos
      setCampoBloqueado(false);
    }
  };
  // Esta funcao vai pegar o cep e enviar para o buscacep e verificar se retorna algo
  const fnBuscaCep = async (e) => {
    // Retira caracteres especiais
    const valor = e.target.value.replace(/_/g, "").replace("-", "").trim();

    if (valor.search(/^[0-9]{8}$/g) === -1) return false;
    // Limpando os campos do cep
    setValue("endereco", "");
    setValue("bairro", "");
    setValue("complemento", "");
    setValue("cidade", "");
    setValue("estado", "");
    setAguardar(true);

    // Vamos agora atualizar os campos
    const resp = await axios.get(`https://viacep.com.br/ws/${valor}/json/`);

    setAguardar(false);

    if (resp.data.erro) return false;

    // Vamos retirar os campos que foram retornados
    const { bairro, complemento, logradouro, localidade, uf } = resp.data;
    // Vamos agora atualizar os campos
    setValue("endereco", logradouro);
    setValue("bairro", bairro);
    setValue("complemento", complemento);
    setValue("cidade", localidade);
    setValue("estado", uf);
  };
  // Esta funcao valida se os campos foram enviados
  const fnValidar = (val) => {
    // Pega o campo numero, se ele nao estiver preenchido coloca-o como 0
    const novoValor = { ...val };
    novoValor["numero"] =
      val.numero && val.numero.length > 0 ? parseInt(val["numero"]) : 0;
    novoValor["cpf"] = val.cpf
      ? val.cpf.replace(/_/g, "").replace(/\./g, "").replace("-", "").trim()
      : val.cpf;
    // Campo data mudou sua nomeclatura agora precisa ser convertido para campo data
    novoValor["nascimento"] = val.nascimento
      ? val.nascimento.replace(/_/g, "")
      : val.nascimento;

    // Tudo validado, vamos avancar
    props.avancar(novoValor);
  };
  // Funcao para validar os campos importantes e impedir que o botao de avancar seja exibido
  const fnLiberarBotaoAvancar = () => {
    let validado = true;
    const cep = watch("cep")
      ? watch("cep").replace("_", "").trim().length === 9
      : false;
    const celular = watch("celular")
      ? watch("celular").replace("_", "").trim()
      : false;
    const cpf =
      watch("cpf")?.replace("_", "").replace(/\./g, "").replace("-", "")
        .length === 11 || false;

    if (!cep || !celular || !cpf) validado = false;

    return validado;
  };

  // Array para criar um preenchedor de de campos
  const CAMPOS = [
    {
      variant: tipoCampo,
      type: "text",
      helpText: !!errors.cpf && "000.000.000-00",
      error: !!errors.cpf,
      name: "cpf",
      defaultValue: "",
      rules: { required: true },
      inputProps: {
        inputMode: "numeric",
        pattern: "[0-9]*",
        autoComplete: "disabled",
      },
      onKeyUp: fnBuscaCPF, //onBlur: fnBuscaCPF,
      gridSystem: { xs: 12, md: 3 },
      label: "* CPF",
      mask: [
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ],
    },
    {
      variant: tipoCampo,
      type: "text",
      disabled: campoBloqueado,
      helperText: !!errors.nome && "Campo obrigatório",
      error: !!errors.nome,
      name: "nome",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, md: 6 },
      label: "* Nome Completo",
      inputLabelProps: { shrink: true },
    },
    {
      variant: tipoCampo,
      type: "email",
      disabled: campoBloqueado,
      helpText: !!errors.email && "Ex: meuemail@exemplo.com",
      error: !!errors.email,
      name: "email",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, md: 3 },
      label: "* Email",
      inputLabelProps: { shrink: true },
    },
    {
      variant: tipoCampo,
      type: "text",
      disabled: campoBloqueado,
      helpText: !!errors.celular && "(31) 99999-9999",
      error: !!errors.celular,
      name: "celular",
      defaultValue: "",
      rules: { required: true },
      inputProps: {
        inputMode: "numeric",
        pattern: "[0-9]*",
        autoComplete: "disabled",
      },
      gridSystem: { xs: 12, md: 3 },
      label: "* Celular",
      inputLabelProps: { shrink: true },
      mask: [
        "(",
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    {
      variant: tipoCampo,
      type: "text",
      disabled: campoBloqueado,
      helpText: !!errors.nascimento && "Formato: dd/mm/aaaa",
      error: !!errors.nascimento,
      name: "nascimento",
      defaultValue: "",
      rules: { required: true },
      inputProps: {
        inputMode: "numeric",
        pattern: "[0-9]*",
        autoComplete: "disabled",
      },
      inputLabelProps: { shrink: true },
      gridSystem: { xs: 12, md: 2 },
      label: "* Data de nascimento",
      mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
    },
    {
      variant: tipoCampo,
      type: "select",
      options: opcoesSexo,
      helpText: !!errors.sexo && "Campo obrigatório",
      disabled: campoBloqueado,
      error: !!errors.sexo,
      name: "sexo",
      defaultValue: "",
      inputLabelProps: { shrink: true },
      rules: { required: true },
      gridSystem: { xs: 12, md: 2 },
      label: "* Sexo",
    },
    {
      variant: tipoCampo,
      type: "select",
      options: opcoesFilho,
      helpText: !!errors.filho && "Obrigatório",
      disabled: campoBloqueado,
      error: !!errors.filho,
      name: "filho",
      defaultValue: "",
      inputLabelProps: { shrink: true },
      rules: { required: true },
      gridSystem: { xs: 12, md: 1 },
      label: "* Filho(s)",
    },
    {
      variant: tipoCampo,
      type: "number",
      defaultValue: "0",
      disabled: campoBloqueado,
      helpText: !!errors.qtd_filho && "Negativos não permitidos",
      error: !!errors.qtd_filho,
      name: "qtd_filho",
      inputProps: { min: 0, autoComplete: "disabled" },
      inputLabelProps: { shrink: true },
      rules: { required: true },
      gridSystem: { xs: 12, md: 2 },
      label: "* Qt. Filhos",
    },
    {
      variant: tipoCampo,
      type: "select",
      options: props?.estadoCivil,
      disabled: campoBloqueado,
      helpText: !!errors.estadoCivil && "Obrigatório",
      inputLabelProps: { shrink: true },
      error: !!errors.estadoCivil,
      name: "estadoCivil",
      defaultValue: "",
      rules: { required: true },
      gridSystem: { xs: 12, md: 2 },
      label: "* Estado civil",
    },
    {
      variant: tipoCampo,
      type: "select",
      options: props?.escolaridade,
      disabled: campoBloqueado,
      helpText: !!errors.escolaridade && "Obrigatório",
      inputLabelProps: { shrink: true },
      error: !!errors.escolaridade,
      name: "escolaridade",
      defaultValue: "",
      rules: { required: true },
      gridSystem: { xs: 12, md: 3 },
      label: "* Escolaridade",
    },
    {
      variant: tipoCampo,
      type: "text",
      helpText: !!errors.cep && "99999-999",
      disabled: campoBloqueado,
      error: !!errors.cep,
      name: "cep",
      defaultValue: "",
      rules: { required: true },
      inputProps: {
        inputMode: "numeric",
        pattern: "[0-9]*",
        autoComplete: "disabled",
      },
      gridSystem: { xs: 12, md: 2 },
      label: "* Cep",
      inputLabelProps: { shrink: true },
      onBlur: fnBuscaCep,
      onKeyUp: fnBuscaCep,
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
    },
    {
      variant: tipoCampo,
      type: "text",
      helperText: !!errors.endereco && "Av das Palmeiras",
      disabled: campoBloqueado,
      error: !!errors.endereco,
      name: "endereco",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, md: 5 },
      label: "* Endereço",
      inputLabelProps: { shrink: true },
    },
    {
      variant: tipoCampo,
      type: "number",
      helperText: !!errors.numero && "Ex: 155",
      disabled: campoBloqueado,
      error: !!errors.numero,
      name: "numero",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, md: 2 },
      label: "Número",
      inputLabelProps: { shrink: true },
    },
    {
      variant: tipoCampo,
      type: "text",
      disabled: campoBloqueado,
      helperText: !!errors.complemento && "Casa 1",
      error: !!errors.complemento,
      name: "complemento",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, md: 2 },
      label: "Complemento",
      inputLabelProps: { shrink: true },
    },
    {
      variant: tipoCampo,
      type: "text",
      helperText: !!errors.bairro && "Ex: Centro",
      disabled: campoBloqueado,
      error: !!errors.bairro,
      name: "bairro",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, md: 4 },
      label: "* Bairro",
      inputLabelProps: { shrink: true },
    },
    {
      variant: tipoCampo,
      type: "text",
      disabled: campoBloqueado,
      helperText: !!errors.cidade && "Ex: Belo Horizonte",
      error: !!errors.cidade,
      name: "cidade",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, md: 4 },
      label: "* Cidade",
      inputLabelProps: { shrink: true },
    },
    {
      variant: tipoCampo,
      type: "text",
      disabled: campoBloqueado,
      helperText: !!errors.estado && "Ex: MG",
      error: !!errors.estado,
      placeholder: "EX: MG",
      name: "estado",
      defaultValue: "",
      rules: { required: true },
      inputProps: { autoComplete: "disabled", maxLength: 2 },
      gridSystem: { xs: 12, md: 2 },
      label: "* Estado",
      inputLabelProps: { shrink: true },
    },
  ];

  return (
    <>
      <motion.div {...props}>
        <Typography align="center" variant="h5">
          Informações pessoais
        </Typography>
        <Grid container spacing={3}>
          {CAMPOS.map((ele, idx) => (
            <RenderCampo control={control} key={idx} ele={ele} />
          ))}
        </Grid>
        <Divider component="br" />
        <Grid container>
          <Hidden smDown>
            <Grid item xs={10} />
          </Hidden>
          <Grid item xs={12} sm={2}>
            {fnLiberarBotaoAvancar() &&
              errors &&
              Object.keys(errors).length < 1 && (
                <Button
                  disabled={
                    (errors && Object.keys(errors).length > 0) || aguardar
                  }
                  onClick={handleSubmit(fnValidar)}
                  endIcon={<ChevronRightIcon />}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  AVANÇAR
                </Button>
              )}
          </Grid>
        </Grid>
      </motion.div>
    </>
  );
};
// Comopnente que exibe o formulario de informacoes profissionais
const InformacoesProfissionais = (props) => {
  // Recebe as experienciasProfissionais cadastradas
  const [expProfissional, setExpProfissional] = useState(
    props.resposta ? props.resposta.experienciasProfissionais : []
  );
  //
  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaInformacoesProfissionais),
  });
  // Funcao para registar os dados de informacoes profissionais
  const fnValidar = (val) => {
    const { dataAdmissao, dataDesligamento } = val;

    val["dataAdmissao"] = format(dataAdmissao, "yyyy-MM-dd");
    val["dataDesligamento"] = format(dataDesligamento, "yyyy-MM-dd");
    val["principaisAtividades"] = val.principaisAtividades
      ? val.principaisAtividades
      : "";

    setExpProfissional((state) => [...state, val]);
    // Limpa os campos pois a experiência profissional foi inclusa
    reset({
      empresa: "",
      cargo: "",
      dataAdmissao: "",
      dataDesligamento: "",
      principaisAtividades: "",
    });
  };
  // Funcao para avancar ao proximo questionario
  const fnAvancar = () => {
    props.avancar({ experienciasProfissionais: expProfissional });
  };
  // Criando o schema para os campos
  const CAMPOS = [
    {
      type: "text",
      variant: tipoCampo,
      label: "Empresa",
      disabled: false,
      name: "empresa",
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, sm: 6 },
    },
    {
      type: "text",
      variant: tipoCampo,
      label: "Cargo",
      disabled: false,
      name: "cargo",
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, sm: 6 },
    },
    {
      type: "date",
      variant: tipoCampo,
      label: "Data de Admissão",
      disabled: false,
      name: "dataAdmissao",
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, sm: 3 },
      inputLabelProps: { shrink: true },
      helpText: !!errors.dataAdmissao && "Ex: 01/01/2000",
      error: !!errors.dataAdmissao,
    },
    {
      type: "date",
      variant: tipoCampo,
      label: "Data de desligamento",
      disabled: false,
      name: "dataDesligamento",
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, sm: 3 },
      inputLabelProps: { shrink: true },
      helpText: !!errors.dataDesligamento && "Ex: 01/01/2000",
      error: !!errors.dataDesligamento,
    },
    {
      type: "textarea",
      variant: tipoCampo,
      label: "Principais Atividades",
      disabled: false,
      name: "principaisAtividades",
      minRows: 1,
      multiline: true,
      inputProps: { autoComplete: "disabled" },
      gridSystem: { xs: 12, sm: 6 },
    },
  ];
  // Funcao para valiar que os campos necessarios para se incluir uma experiencia profissional estão preechidos
  const validarPreenchimentoExpProf = () => {
    let correto = false;
    // Validando para ver se os campos de experiencia estao preenchidos
    const desligamento = watch("dataDesligamento");
    const admissao = watch("dataAdmissao");
    const empresa = watch("empresa")?.length;
    const cargo = watch("cargo")?.length;

    if (desligamento && admissao && empresa > 0 && cargo > 0) {
      correto = true;
    }
    return correto;
  };

  return (
    <motion.div {...props}>
      <Typography align="center" variant="h5">
        Experiência(s) profissiona(is)
      </Typography>
      <Typography align="center" variant="body2">
        Inclua das mais recentes as antigas (se houver).
      </Typography>
      {expProfissional &&
        expProfissional.map((ele, idx) => {
          // Extraindo os campos que serao usado no formulario
          const {
            empresa,
            cargo,
            dataAdmissao,
            dataDesligamento,
            principaisAtividades,
          } = ele;
          if (!empresa) return null;
          // Array interno para mapear dados em comum
          const arr = [
            { value: empresa, label: "Empresa" },
            { value: cargo, label: "Cargo" },
            { value: dataAdmissao, label: "Data de Admissão" },
            { value: dataDesligamento, label: "Data de desligamento" },
            { value: principaisAtividades, label: "Principais Atividades" },
          ];

          return (
            <>
              <Grid key={idx} sx={{ mt: 0.5 }} container spacing={2}>
                {arr.map((obj, ix) => {
                  const eData = [2, 3].includes(ix);
                  return (
                    <Grid key={ix} item xs={12} sm={eData ? 3 : 6}>
                      <TextField
                        fullWidth
                        label={obj.label}
                        disabled={true}
                        inputProps={{ autoComplete: "disabled" }}
                        type={eData ? "date" : "text"}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={
                          eData
                            ? format(parseISO(obj.value), "yyyy-MM-dd")
                            : obj.value
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Divider component="br" />
              <Divider />
              <Divider component="br" />
            </>
          );
        })}
      <>
        <Grid container spacing={2}>
          {CAMPOS.map((ele, idx) => {
            // Veja se a data de admissao ja foi preenchida e defina ela como a data minima para a data de desligamento
            let inputProps;
            if (idx === 3) {
              const valor = watch("dataAdmissao");
              if (
                valor &&
                valor?.search(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i) !== -1
              ) {
                inputProps = { min: format(parseISO(valor), "yyyy-MM-dd") };
              }
            }

            return (
              <RenderCampo
                inputProps={inputProps}
                key={idx}
                ele={ele}
                control={control}
              />
            );
          })}
          <Hidden smDown>
            <Grid item sm={5} />
          </Hidden>
          <Grid item xs={12} sm={2}>
            <Button
              startIcon={<AddIcon />}
              fullWidth
              sx={{ borderRadius: 8 }}
              disabled={!validarPreenchimentoExpProf()}
              title="PREENCHA OS CAMPOS PARA LIBERAR INCLUSÃO"
              color="primary"
              variant="contained"
              onClick={handleSubmit(fnValidar)}
            >
              {" "}
              ADICIONAR{" "}
            </Button>
          </Grid>
          <Hidden smDown>
            <Grid item sm={5} />
          </Hidden>
        </Grid>
        <Divider component="br" />
        <Divider component="br" />
      </>
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid item xs={3} />
        </Hidden>
        <Grid item xs={6} sm={3}>
          <Button
            startIcon={<ChevronLeftIcon />}
            fullWidth
            color="primary"
            variant="outlined"
            onClick={props.recuar}
          >
            RECUAR
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            endIcon={<DoubleArrowIcon />}
            fullWidth
            color="primary"
            variant="contained"
            onClick={fnAvancar}
          >
            AVANCAR
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};
// Componente que exibe as regioes que o futuro funcionario tem disponibilidade
const DisponibilidadeDeLoja = (props) => {
  const { resposta, avancar, recuar } = props;

  const { register, handleSubmit } = useForm();
  //
  let disponibilidade;
  if (resposta && resposta.disponibilidade)
    disponibilidade = resposta.disponibilidade;

  // Funcao para pegar os valores checados
  const fnValidar = (val) => {
    // Verifica quais lojas o usuario cita disponibilidade
    const disponibilidade = [];
    Object.keys(val).forEach((valor) => {
      // Se nao estiver falso ele foi marcado
      if (val[valor]) disponibilidade.push(parseInt(val[valor]));
    });
    // Se nao tiver nenhuma disponibilidade nao faz sentido prosseguir
    if (disponibilidade.length < 1) {
      toast.dark("Marque ao menos uma loja", {
        type: "error",
      });
      return false;
    }
    // Esta funcao registra as lojas marcadas para disponibilidade e prossege
    avancar({ disponibilidade });
  };
  return (
    <motion.div {...props}>
      <Typography variant="h5" align="center">
        Marque todos os locais disponiveis para trabalho
      </Typography>
      <Grid container>
        {Object.keys(props.regioes).map((key, idx) => (
          <>
            <Grid item xs={12} key={idx}>
              <Typography key={`titulo_${idx}_${key}`} variant="h6">
                {key}
              </Typography>
              <Grid container key={`container_${idx}_${key}`}>
                {props.regioes[key].map((arr, idx2) => {
                  const checado =
                    disponibilidade && disponibilidade.includes(arr[0]);

                  return (
                    <Grid item xs={12} sm={3} key={`item_${idx}_${arr[1]}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`${idx}_${idx2}`}
                            value={arr[0]}
                            defaultChecked={checado}
                            {...register(arr[1])}
                          />
                        }
                        label={arr[1]}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Divider />
              <Divider component="br" />
            </Grid>
          </>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid item xs={3} />
        </Hidden>
        <Grid item xs={6} sm={3}>
          <Button
            startIcon={<ChevronLeftIcon />}
            fullWidth
            color="primary"
            variant="outlined"
            onClick={recuar}
          >
            RECUAR
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            endIcon={<DoubleArrowIcon />}
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleSubmit(fnValidar)}
          >
            AVANÇAR
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};
// Componente para exibir os cargos que o candidato tem interesse
const CargoDeInteresse = (props) => {
  const { resposta, avancar, recuar } = props;

  const { register, handleSubmit } = useForm();
  //
  let cargo;
  if (resposta && resposta.cargo) cargo = resposta.cargo;

  // Funcao para pegar os valores checados
  const fnValidar = (val) => {
    // Verifica quais lojas o usuario cita cargo
    const cargo = [];
    Object.keys(val).forEach((valor) => {
      // Se nao estiver falso ele foi marcado
      if (val[valor]) cargo.push(parseInt(val[valor]));
    });
    // Se nao tiver nenhuma cargo nao faz sentido prosseguir
    if (cargo.length < 1) {
      toast.dark("Marque ao menos um cargo", {
        type: "error",
      });
      return false;
    }
    // Esta funcao registra os locais marcados para cargo e prossege
    avancar({ cargo });
  };

  return (
    <motion.div {...props}>
      <Typography variant="h5" align="center">
        Marque as vagas que você tem interesse
      </Typography>
      <Grid container>
        {props?.cargo?.map((arr, idx) => {
          const checado = cargo && cargo.includes(arr[0]);
          return (
            <Grid item xs={12} sm={12} key={`item_${arr[0]}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${idx}_${arr[0]}`}
                    value={arr[0]}
                    defaultChecked={checado}
                    {...register(arr[1])}
                  />
                }
                label={arr[1]}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid item xs={3} />
        </Hidden>
        <Grid item xs={6} sm={3}>
          <Button
            startIcon={<ChevronLeftIcon />}
            fullWidth
            color="primary"
            variant="outlined"
            onClick={recuar}
          >
            RECUAR
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            endIcon={<SaveIcon />}
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleSubmit(fnValidar)}
          >
            CONCLUIR
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};
// Componente que pega todas as respostas do questionario e as exibem para uma revisão
const VerRespostasPreenchidas = (props) => {
  // colocando um loading
  const [carregar, setCarregar] = useState(null);

  const { respostas, recuar, setExibirTermo } = props;
  const history = useHistory();

  const [checado, setChecado] = useState(false);

  // Este codigo vai evitar o formulario de quebrar
  if (respostas.length < 5) return null;
  const [infoPessoais, experienciasProfissionais, disponibilidade, cargo] =
    respostas;
  console.log(cargo);

  let disponibilidadeDeLoja;
  if (disponibilidade) disponibilidadeDeLoja = disponibilidade.disponibilidade;

  let cargoElegivel;
  if (cargo) cargoElegivel = cargo.cargo;

  // Funcao de callback para determinar se o termo aceite foi confirmado
  const fnChecado = (e) => {
    setChecado(e.target.checked);
  };
  // Funcao para definir o formulario como concluido
  const fnSubmeterDados = async () => {
    setCarregar(true);
    const dados = { ...respostas[0] }; // Informacoes pessoais
    dados["celular"] = dados["celular"]
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "");
    // Precisa formatar a data de nascimento para o padrao aceito pela api AAAA-MM-DD
    dados["nascimento"] = dados["nascimento"]
      .replace(/\//g, "-")
      .split("-")
      .reverse()
      .join("-");

    dados["experienciasProfissionais"] =
      respostas[1]["experienciasProfissionais"]; // Experiencias profissionais
    dados["disponibilidade"] = respostas[2]["disponibilidade"];
    dados["cargo"] = respostas[3]["cargo"];
    // Reseta o captcha e recria-o
    await props.recaptchaRef.current.reset();
    dados["captcha"] = await props.recaptchaRef.current.executeAsync();

    //dados['captcha'] = captcha;
    // Obtenha o IP local do usuario e anexe aos dados
    try {
      const respIP = await axios.get("https://httpbin.org/ip");
      if (respIP.status === 200) {
        dados["ip"] = respIP.data?.origin;
      }
    } catch (error) {
      console.log(error);
    }

    const formData = new FormData();
    formData.append("dados", JSON.stringify(dados));

    // Envia os dados e aguarda a resposta
    const TOAST = {
      style: { backgroundColor: "#141414", color: "white" },
      autoClose: 5000,
    };

    axios
      .post("/salvar_curriculo", formData)
      .then((resp) => {
        if (resp.status !== 200) {
          toast.error(
            " ERRO INTERNO DO SERVIDOR, SE PERSISTIR INFORMAR AO ADMIN. 👨🏽‍🔧",
            TOAST
          );
          return false;
        }
        // Veja se tem alguma mensagem de erro
        if (resp.data.erro) {
          toast.error(resp.data.erro);
          return false;
        }
        // Deu certo vá para a próxima pagina
        history.replace("/cadastro_conclusao");
      })
      .catch(async (err) => {
        console.log(err);
        // Envia este erro desconhecido para o servidor armazena-lo
        // Reseta o captcha e recria-o
        await props.recaptchaRef.current.reset();
        const erroCaptcha = await props.recaptchaRef.current.executeAsync();
        const formErro = new FormData();
        formErro.append(
          "dados",
          JSON.stringify({ erro: err, captcha: erroCaptcha })
        );

        await axios.post("/gravar_erro_front", formErro);

        toast.error(`ERRO DESCONHECIDO, 👨🏽‍🔧`, TOAST);
      })
      .finally(() => {
        setCarregar(false);
      });
  };

  return (
    <motion.div {...props}>
      <Typography align="center" variant="h4">
        Estamos quase lá
      </Typography>
      <Typography component="p" align="center" variant="body2">
        Verifique todas as informações que você inseriu no cadastro. Caso
        necessite alterar algo pode retornar e fazer as alterações.
      </Typography>
      <Divider />
      <br />
      <Typography color="secondary" align="center" variant="h5">
        Informações pessoais
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            variant={tipoCampo}
            fullWidth
            label="Nome completo"
            disabled={true}
            type="text"
            defaultValue={infoPessoais.nome}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            type="email"
            defaultValue={infoPessoais.email}
            fullWidth
            variant={tipoCampo}
            label="Email"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="Celular (WhatsApp)"
            defaultValue={infoPessoais.celular}
            disabled={true}
          />
        </Grid>
        {/* LINHA ABAIXO */}
        <Grid item xs={12} md={3}>
          <TextField
            type="text"
            fullWidth
            variant={tipoCampo}
            label="Data de nascimento"
            defaultValue={infoPessoais.nascimento}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            options={opcoesSexo}
            fullWidth
            variant={tipoCampo}
            label="Sexo"
            disabled={true}
            defaultValue={infoPessoais.sexo}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Select
            options={opcoesFilho}
            fullWidth
            variant={tipoCampo}
            label="Filho(s)"
            disabled={true}
            defaultValue={infoPessoais.filho}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            options={props?.estadoCivil}
            fullWidth
            variant={tipoCampo}
            label="Estado civil"
            disabled={true}
            defaultValue={infoPessoais.estadoCivil}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            options={props?.escolaridade}
            fullWidth
            variant={tipoCampo}
            label="Escolaridade"
            disabled={true}
            defaultValue={infoPessoais.escolaridade}
          />
        </Grid>
        {/* INFORMACOES DE ENDERECO */}
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="CEP"
            disabled={true}
            defaultValue={infoPessoais.cep}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="Endereço"
            disabled={true}
            defaultValue={infoPessoais.endereco}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="Número"
            disabled={true}
            defaultValue={infoPessoais.numero}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="Complemento"
            disabled={true}
            defaultValue={infoPessoais.complemento}
          />
        </Grid>
        {/* BAIRRO, CIDADE E ESTADO */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="Bairro"
            disabled={true}
            defaultValue={infoPessoais.bairro}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="Cidade"
            disabled={true}
            defaultValue={infoPessoais.cidade}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant={tipoCampo}
            label="Estado"
            disabled={true}
            defaultValue={infoPessoais.estado}
          />
        </Grid>
      </Grid>
      <Divider component="br" />
      <Typography color="secondary" align="center" variant="h5">
        Informações profissionais
      </Typography>
      {experienciasProfissionais &&
      experienciasProfissionais.experienciasProfissionais &&
      experienciasProfissionais.experienciasProfissionais?.length > 0 ? (
        experienciasProfissionais.experienciasProfissionais.map((ele, idx) => (
          <Grid key={idx} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={tipoCampo}
                fullWidth
                label="Empresa"
                disabled={true}
                defaultValue={ele.empresa}
                type="text"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={tipoCampo}
                fullWidth
                label="Cargo"
                disabled={true}
                defaultValue={ele.cargo}
                type="text"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                type="date"
                variant={tipoCampo}
                fullWidth
                label="Data de admissão:"
                disabled={true}
                defaultValue={format(parseISO(ele.dataAdmissao), "yyyy-MM-dd")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                type="date"
                variant={tipoCampo}
                fullWidth
                label="Data de desligamento:"
                disabled={true}
                defaultValue={format(
                  parseISO(ele.dataDesligamento),
                  "yyyy-MM-dd"
                )}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={tipoCampo}
                fullWidth
                label="Principais atividades:"
                disabled={true}
                defaultValue={ele.principaisAtividades}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography variant="body2" component="p" align="center">
          Você não preencheu nenhuma experiência profissional no seu currículo.
        </Typography>
      )}
      <Divider />
      <Divider component="br" />
      <Typography color="secondary" align="center" variant="h5">
        Disponibilidade(s) de loja(s) escolhidas para trabalho
      </Typography>
      <Grid container>
        {Object.keys(props.regioes).map((key, idx) => {
          // Verifica se a regiao tem alguma disponibilidade
          const existeUm = props.regioes[key].some(
            (ele) =>
              disponibilidadeDeLoja && disponibilidadeDeLoja.includes(ele[0])
          );
          if (!existeUm) return null;

          return (
            <Grid item xs={12} key={idx}>
              <Typography key={`titulo_${idx}_${key}`} variant="h6">
                {key}
              </Typography>
              <Grid container key={`container_${idx}_${key}`}>
                {props.regioes[key].map((arr, idx2) => {
                  const checado =
                    disponibilidadeDeLoja &&
                    disponibilidadeDeLoja.includes(arr[0]);
                  if (!checado) return null;

                  return (
                    <Grid item xs={12} md={3} key={`item_${idx}_${arr[1]}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`${idx}_${idx2}`}
                            checked={checado}
                            onClick={() =>
                              window.alert(
                                "CASO DESEJE ALTERAR RETORNE PARA DISPONIBILIDADE."
                              )
                            }
                          />
                        }
                        label={arr[1]}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Divider />
              <Divider component="br" />
            </Grid>
          );
        })}
      </Grid>
      <Divider />
      <Divider component="br" />
      <Typography color="secondary" align="center" variant="h5">
        Vagas que você tem interesse
      </Typography>
      <Grid container>
        {props?.cargo?.map((arr, idx) => {
          const checado = cargoElegivel && cargoElegivel.includes(arr[0]);
          if (!checado) return null;
          return (
            <Grid item xs={12} sm={12} key={`item_${arr[0]}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${idx}_${arr[0]}`}
                    value={arr[0]}
                    checked={checado}
                    onClick={() =>
                      window.alert("CASO DESEJE ALTERAR RETORNE UM PASSO.")
                    }
                  />
                }
                label={arr[1]}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid container>
        <Hidden smDown>
          <Grid item sm={3} />
        </Hidden>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            onChange={fnChecado}
            defaultChecked={checado}
            control={<Checkbox />}
            label="Li e aceito os"
          />
          <Button
            size="medium"
            onClick={() => setExibirTermo(true)}
            color="secondary"
            variant="contained"
          >
            TERMOS
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid item sm={3} />
        </Hidden>
      </Grid>
      {/* <Grid container>
              <Hidden smDown>
                  <Grid item sm={3} />
              </Hidden>
              <Grid item xs={12} sm={6}>
                  <ReCAPTCHA sitekey='6LcVK5gcAAAAADZObtId-YPUKmk-b2b_Q6YAvPEO' 
                    onChange={valor=> setCaptcha(valor)} 
                />
              </Grid>
              <Hidden smDown>
                  <Grid item sm={3} />
              </Hidden>
          </Grid> */}
      <Divider component="br" />
      <Divider />
      <Divider component="br" />
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid item sm={3} />
        </Hidden>
        <Grid item xs={6} sm={3}>
          <Button
            disabled={carregar}
            onClick={recuar}
            startIcon={<ChevronLeftIcon />}
            fullWidth
            color="primary"
            variant="outlined"
          >
            RETORNAR
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            disabled={carregar || !checado}
            endIcon={
              carregar ? <CircularProgress size={20} /> : <PostAddIcon />
            }
            fullWidth
            onClick={fnSubmeterDados}
            color="primary"
            variant="contained"
          >
            CONCLUIR
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid item sm={3} />
        </Hidden>
      </Grid>
    </motion.div>
  );
};

export default Cadastro;
